import React, { useState } from 'react'
import { graphql, navigate } from 'gatsby'
import {
  Container,
  SimpleGrid,
  Grid,
  GridItem,
  Heading,
  Box,
  Stack,
  Text,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Button,
  useMediaQuery,
} from '@chakra-ui/react'
import Layout from '../../components/layout'
import FlatBorder from '../../components/funky-border'
import ImageFrag from '../../components/image-frag'
import Seo from '../../components/seo'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const TestimonialAccordion = ({ children }) => {
  return (
    <Accordion allowToggle my={4}>
      <AccordionItem borderTop={0} borderBottom={0}>
        <h2>
          <AccordionButton
            _focus={{
              boxShadow: `none`,
            }}
            display="inline"
            pr={0}
            pl={0}
            textAlign="left"
          >
            <Box display="inline-block" textAlign="left" fontWeight="bold">
              Mud fans say
            </Box>
            <AccordionIcon display="inline-block" textAlign="left" />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

const Product = ({
  data: { shopifyCollection },
  pageContext: { breadcrumb },
}) => {
  const { image, title } = shopifyCollection

  const [isSmallerThan1024] = useMediaQuery('(max-width: 1024px)')
  // const [ setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [slider1, setSlider1] = useState(null)
  const [slider2] = useState(null)

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav',
    adaptiveHeight: true,
  }

  React.useEffect(() => {
    // trackProductView()
    // setNav1(slider1)
    setNav2(slider2)
  }, [slider1, slider2])

  const bgGradient = useColorModeValue(`bg`, `dark.bg`)

  const headingColor = useColorModeValue(`black`, `white`)

  const defaultBorderColour = useColorModeValue(`brand.gray.500`)

  const [, setBorderColorScheme] = useState(defaultBorderColour)

  return (
    <Layout breadcrumbs={breadcrumb}>
      <Seo
        title="Create Your Own Pie Gift Box | Mud Foods"
        description="What could be more fun than receiving a box full of delicious pies in the post? Create your own gift box here."
      />
      <Box bgGradient={bgGradient}>
        <Container py={[8, 12, 12]}>
          <SimpleGrid columns={[1, 1, 2]} spacingX="40px" spacingY="20px">
            <Stack spacing={[8, 16]}>
              <Stack spacing={4}>
                <Heading as="h1" color={headingColor}>
                  {title}
                </Heading>

                <Text>
                  What could be more fun than receiving a box full of delicious
                  pies in the post?
                </Text>
                <Text>
                  If you know what they’d like, create your own selection.
                  Specify the correct shipping address at checkout and your gift
                  box will soon be on its way.
                </Text>
                <Text>Easy as pie!</Text>
                <TestimonialAccordion>
                  <Text>
                    “Our present of six mixed pies/squiches have just arrived at
                    our son’s in London. They have dived in straight away -
                    steak and stilton and red onion and marmalade in the oven as
                    we speak. Really happy. Easy process from ordering to
                    delivery. Great service, thank you 👏🏻”
                  </Text>
                  <Heading as="h6" fontSize="md">
                    Carol D, Droitwich Spa, Worcestershire
                  </Heading>
                </TestimonialAccordion>
              </Stack>
              <Stack spacing={0}>
                <Grid
                  gap={4}
                  templateColumns={['repeat(4, 1fr)']}
                  as="form"
                  noValidate
                  direction={['row']}
                  flexWrap="wrap"
                  justifyItems="inherit"
                >
                  {!isSmallerThan1024 && (
                    <GridItem colSpan={2} alignSelf="end">
                      {' '}
                      <Button
                        w="100%"
                        variant="outline"
                        alignSelf="flex-end"
                        onClick={() => navigate('/')}
                        colorScheme="brand.gray"
                      >
                        Continue shopping
                      </Button>
                    </GridItem>
                  )}
                  <GridItem colSpan={2} alignSelf="end">
                    {' '}
                    <Button
                      w="100%"
                      alignSelf="flex-end"
                      onClick={() =>
                        navigate('/collections/all-products-pies-by-post')
                      }
                      colorScheme="brand.green"
                    >
                      Choose Pies
                    </Button>
                  </GridItem>
                  <GridItem colSpan={[4, 4, 4, 2]}>
                    {/* <AddToCart
                      w="100%"
                      type="button"
                      colorScheme={borderColourScheme}
                      variantId={0}
                      product={product}
                      quantity={1}
                      available={available}
                      alignSelf="flex-end"
                      mt={4}
                      pr={8}
                      pl={8}
                      boxShadow="md"
                    /> */}
                  </GridItem>
                </Grid>
              </Stack>
            </Stack>

            <Box
              data-name="product-image-wrapper"
              order={[1, null, 2]}
              maxW="100%"
            >
              <FlatBorder setBorderColorScheme={setBorderColorScheme}>
                <Box className="thumbnail-slider-wrap">
                  <Slider
                    {...settingsMain}
                    asNavFor={nav2}
                    ref={(slider) => setSlider1(slider)}
                  >
                    {image &&
                      image.localFile &&
                      image.localFile.childImageSharp && (
                        <ImageFrag
                          imageData={image}
                          key={`product-image-gift-box-21213`}
                          alt="a gift box"
                        />
                      )}
                  </Slider>
                </Box>
              </FlatBorder>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>
    </Layout>
  )
}
export default Product

export const query = graphql`
  query GiftBoxCollection {
    shopifyCollection(handle: { in: "gifts" }) {
      title
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              formats: [AUTO, WEBP]
              quality: 40
              width: 600
            )
          }
        }
      }
    }
  }
`
